<template>
  <vx-card class="grid-view-item mb-1 p-2 overflow-hidden cursor-pointer" @click="navigate_to_category">
    <template slot="no-body">
      <div class="bg-white p-4 flex items-center justify-center">
        <img :src="base_image.path" :alt="category.name" class=" h-24 md:h-32 px-4">
      </div>
      <div class="item-details py-3">
        <div class="text-center">
          <h6 class="truncate-two-line">{{ category.name }}</h6>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
    export default {
        name: "Category",
        props:{
            category:{
              required:true,
              type: Object
            }
        },
        data(){
          return{
            baseUrl:  process.env.VUE_APP_URL
          }
        },
      computed:{
        base_image(){
          if (this.category.files.length){
            return this.category.files[0];
          }else{
            return {path:this.baseUrl+"/storage/media/no_image.png"}
          }
        }
      },
        methods:{
          navigate_to_category(){
              this.$router.push('/products?category='+this.category.slug)
          }
        }
    }
</script>

<style scoped>
  .truncate-two-line {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: pre-wrap;
    line-height: 18px;
    width: 100%;
    font-size: 14px;
    height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
