<template>
	<div class="vx-row">
    <div class="vx-col category-grid lg:w-1/6 sm:w-1/3 w-1/3" v-for="item in $store.state.categories" :key="item.id">
      <category :category="item">
      </category>
    </div>
  </div>
</template>
<script>
  import Category from "./components/Category";
  export default {
    components: {
      Category
    }
  }
</script>
<style>
  .category-grid {
    padding: 0.3rem 0.5rem !important;
  }
  @media (max-width: 767px) {
    .category-grid {
      padding: 0rem 0.15rem !important;
    }
  }
</style>
